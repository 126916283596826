<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppTitle from "@/Components/Shared/title/AppTitle.vue";
import AppImage from "@/Components/Shared/image/AppImage.vue";
import AppText from "@/Components/Shared/text/AppText.vue";
import { computed } from "vue";
import AppCardPopover from "@/Components/Shared/card/AppCardPopover.vue";
import AppIcon from "@/Components/Shared/icon/AppIcon.vue";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "@/Components/Shared/tooltip";
import AppBadge from "@/Components/Shared/badge/AppBadge.vue";
import AppButton from "@/Components/Shared/button/AppButton.vue";
import AppWishlistButton from "@/Components/App/wishlists/AppWishlistButton.vue";
import AppMenuItem from "@/Components/Shared/menu/AppMenuItem.vue";
import AppMenu from "@/Components/Shared/menu/AppMenu.vue";
import AppDropdown from "@/Components/Shared/dropdown/AppDropdown.vue";
import {isLoggedIn} from "@/Utils/Helpers";

type CardAs = "a" | "button";
export type CardSize = "regular" | "large";

type Props = {
    card: {
        image: string;
        name: string;
        path: string;
        set: string;
    };
    size?: CardSize;
    showSet?: boolean;
    as?: CardAs;
};
const { card, showSet = false, size = "regular" } = defineProps<Props>();

// classes
const classes: AntlerClasses<Props> = {
    base: "flex flex-col border border-slate-100 rounded text-center h-full bg-white",
};
const { aClass } = installAntlerComponent("card", {size}, classes);

const wrapperClasses: AntlerClasses<Props> = {
    base: "flex items-center justify-center rounded",
    variants: {
        size: {
            regular: "h-[160px]",
            large: "h-[280px]",
        }
    }
};
const { aClass: aWrapperClass } = installAntlerComponent("card-wrapper", {size}, wrapperClasses);
</script>

<template>
    <div :class="aClass()">
        <AppCardPopover :card-id="card.id">
            <AppLink
                class="flex flex-col items-center p-3 pb-2 grow gap-2 hover:no-underline hover:bg-slate-50"
                :href="card.path"
            >
                <figure :class="aWrapperClass()">
                    <AppImage
                        :src="card.image"
                        :alt="card.name"
                        placeholder
                        class="w-auto"
                    />
                </figure>

                <div class="flex flex-col gap-1">
                    <AppTitle
                        as="h3"
                        size="xxsmall"
                        class="line-clamp-2"
                    >
                        {{ card.name }}
                    </AppTitle>

                    <AppText
                        v-if="card.set && showSet"
                        size="small"
                        color="grayLight"
                    >
                        {{ card.set.name }}
                    </AppText>
                </div>
            </AppLink>
        </AppCardPopover>

        <div v-if="card.is_wishlist || isLoggedIn" class="flex items-center justify-between border-t border-slate-100 pt-1 pb-1 px-2 mt-auto">
            <TooltipProvider v-if="card.is_wishlist">
                <Tooltip>
                    <TooltipTrigger>
                        <AppButton icon="wishlist" variant="round" size="small" icon-color="gray" />
                    </TooltipTrigger>
                    <TooltipContent>
                        Deze kaart zit in je verlanglijstje
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>

            <AppDropdown
                v-if="isLoggedIn"
                :variant="['relative', 'right']"
                size="small"
                :caret="false"
                class="ml-auto"
                offset="lt-sm:-mr-4"
            >
                <template #toggle>
                    <AppButton icon="dots" variant="ghost" size="small" icon-color="gray" />
                </template>
                <AppMenu>
                    <AppMenuItem>
                        <AppWishlistButton
                            :card-id="card.id"
                            :wishlist="card.wishlist"
                            button-variant="ghost"
                            button-align="left"
                            :button-size="['full', 'small']"
                        />
                    </AppMenuItem>
                </AppMenu>
            </AppDropdown>
        </div>
    </div>
</template>
